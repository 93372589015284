class IframeService {
    static inIframe = false;
    static init () {
        IframeService.inIframe = (window === window.parent) ? false : true;
        let root = document.documentElement;
        if(root && IframeService.inIframe) {
            root.style.setProperty('--secondary-c', 'rgba(220,220,220, 0.5)');
        }
    }
    static isInIframe () {
        return IframeService.inIframe;
    }

    static cancelIframe() {
        IframeService.sendMessageFromIframe({status: 'cancel'});
      }
    
    static sendSuccessEventToIframe(event: any) {
        IframeService.sendMessageFromIframe({status: 'success', event});
    }

    static sendErrorEventToIframe (event: any) {
        IframeService.sendMessageFromIframe({status: 'error', event});
    }
    
    static sendMessageFromIframe (data: any) {
        if(IframeService.isInIframe()) {
            (window as any).parent.postMessage(data, "*");
        }
        
    }
}

IframeService.init();


export default IframeService;