import React, { useState, InputHTMLAttributes } from 'react';
import MaskedInput from 'react-text-mask';
import { TextField } from '@material-ui/core';
import { TextFieldProps, OutlinedTextFieldProps } from '@material-ui/core/TextField';
import './MonthField.scss';

const TextMaskCustom = (props: InputHTMLAttributes<{}> & TextFieldProps) => {
  const {
    inputRef,
    ...other
  } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        // @ts-ignore
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-3]/, /\d/, ' ', '/', ' ',
        /\d/, /\d/,
      ]}
      placeholderChar={"_"}
      showMask
    />
  );
}

const MonthField = function ({
  value,
  onChange,
  onBlur,
  label,
  variant,
  margin,
  name,
  fullWidth,
}: OutlinedTextFieldProps) {
  const [maskedValue, setMaskedValue] = useState(value);
  const handleChange = function (e: any) {
    setMaskedValue(e.target.value);
    onChange && onChange(e);
  }
  return (
    <>
      <TextField
        label={label}
        variant={variant}
        margin={margin}
        fullWidth={fullWidth}
        InputProps={{
          inputComponent: TextMaskCustom,
          value: maskedValue,
          name: name,
          onChange: handleChange,
          onBlur: onBlur,
        }}
      />
    </>
  );
}

export default MonthField;