import React, { 
  useState, 
  // InputHTMLAttributes 
} from 'react';
// import MaskedInput from 'react-text-mask';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';
// import MaterialInput from '@material-ui/core/Input';
import { 
  // TextFieldProps, 
  OutlinedTextFieldProps 
} from '@material-ui/core/TextField';
import masterCardBrand from '../../assets/svg/logos/icons8-mastercard-50.svg';
import visaBrand from '../../assets/svg/logos/icons8-visa-50.svg';
import americanExpressBrand from '../../assets/svg/logos/icons8-american-express-50.svg';
import discoverBrand from '../../assets/svg/logos/icons8-discover-50.svg';
import './CardNumberField.scss';
// import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';

interface ICardIcon {
  value?: string;
}

const CardIcon = function ({ value }: ICardIcon) {
  const [firstDigit] = value ? value.split('') : [''];
  let iconSource;
  switch (firstDigit) {
    case '3': iconSource = americanExpressBrand; break;
    case '4': iconSource = visaBrand; break;
    case '5': iconSource = masterCardBrand; break;
    case '6': iconSource = discoverBrand; break;
    default: iconSource = '';
  }
  return <div className="card_number_field-icon">
    {
      iconSource &&
      <img src={iconSource} alt="card icon" />
    }
  </div>;
}

// const TextMaskCustom = (props: InputHTMLAttributes<{}> & TextFieldProps & { mask: any }) => {
//   const {
//     inputRef,
//     mask,
//     ...other
//   } = props;

//   return (
//     // <MaskedInput
//     //   {...other}
//     //   ref={ref => {
//     //     // @ts-ignore
//     //     inputRef(ref ? ref.inputElement : null);
//     //   }}
//     //   mask={mask || [
//     //     /[3-6]/, /\d/, /\d/, /\d/, ' ',
//     //     /\d/, /\d/, /\d/, /\d/, ' ',
//     //     /\d/, /\d/, /\d/, /\d/, ' ',
//     //     /\d/, /\d/, /\d/, /\d/,
//     //   ]}
//     //   placeholderChar={"_"}
//     //   showMask
//     // />
//     <InputMask
//       mask={mask || "6999 9999 9999 9999"}
//       formatChars={{
//         '6': '[3-6]',
//         '9': '[0-9]',
//         'a': '[A-Za-z]',
//         '*': '[A-Za-z0-9]'
//       }}
//       value={props.value}
//       onChange={props.onChange}
//     >
//       {(inputProps: any) => <TextField
//         {...inputProps} name={props.name} disableUnderline />}
//     </InputMask>
//   );
// }

const CardNumberField = function ({
  value,
  onChange,
  onBlur,
  label,
  variant,
  margin,
  name,
  fullWidth,
  helperText,
  error,
  mask,
}: OutlinedTextFieldProps & { mask: any }) {
  const [maskedValue, setMaskedValue] = useState(value);
  const handleChange = function (e: any) {
    setMaskedValue(e.target.value);
    onChange && onChange(e);
  }
  return (
    <div className="card_number_field">
      <InputMask
        mask={mask || "6999 9999 9999 9999"}
        formatChars={{
          '6': '[3-6]',
          '9': '[0-9]',
          'a': '[A-Za-z]',
          '*': '[A-Za-z0-9]'
        }}
        value={value as any}
        onChange={handleChange}
        onBlur={onBlur}
      >
        {(inputProps: any) => <TextField
          {...inputProps} 
          name={name}
          label={label}
          variant={variant}
          margin={margin}
          fullWidth={fullWidth}
          helperText={helperText}
          error={error}
        />}
      </InputMask>
      {/* <TextField
        label={label}
        variant={variant}
        margin={margin}
        fullWidth={fullWidth}
        helperText={helperText}
        error={error}
        InputProps={{
          inputComponent: TextMaskCustom,
          value: maskedValue,
          name: name,
          onChange: handleChange,
          onBlur: onBlur,
        } as Partial<OutlinedInputProps>}
        inputProps={{
          mask,
        }}
      /> */}
      <CardIcon value={maskedValue as string} />
    </div>
  );
}


export default CardNumberField;