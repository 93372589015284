import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React, { FunctionComponent } from "react";
import IframeService from "../../services/iframeService";

const IframeWrapper: FunctionComponent<{}> = (props: any) => {
    if (IframeService.isInIframe()) {
        return <React.Fragment>{props.children}</React.Fragment>
    }

    return null;
};

export default IframeWrapper;