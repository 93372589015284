import React, { useState, useEffect, useRef } from 'react';

interface ICountdown {
  startFrom: number;
  onCountsEnd: () => void;
}

const Countdown: React.FunctionComponent<ICountdown> = function ({
  startFrom,
  onCountsEnd,
}) {

  const [currentNumber, setCurrentNumber] = useState(startFrom);
  const [delay, setDelay] = useState<number | null>(1000);

  if (currentNumber === 0) {
    onCountsEnd();
  }

  useInterval(() => {
    setCurrentNumber(currentNumber - 1);
  }, delay);

  return <span>
    {currentNumber}
  </span>;
}

const useInterval = (callback: any, delay: number | null) => {
  const savedCallback = useRef();

  useEffect(
    () => {
      savedCallback.current = callback;
    },
    [callback]
  );

  useEffect(
    () => {
      // @ts-ignore
      const handler = (...args: any) => savedCallback.current(...args);

      if (delay !== null) {
        const id = setInterval(handler, delay);
        return () => clearInterval(id);
      }
    },
    [delay]
  );
};

export default Countdown;