import React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Typography } from '@material-ui/core';

const ProcParamErrorPage = function () {
  return <div className="payment_message">
    <div className="payment_message-icon">
      <HighlightOffIcon
        color="error"
        fontSize="inherit"
      />
    </div>
    <Typography align="center" component="h1">
      <Typography>
        <Typography align="center" component="p">
          Wrong processing gateway is provided. Please check the URL and try again.
          </Typography>
      </Typography>
    </Typography>
  </div>
}

export default ProcParamErrorPage;