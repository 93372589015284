import React, { useState, useRef, useEffect } from 'react';
import { FormControl, InputLabel, OutlinedInput, Button, Typography, InputAdornment } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import './EditField.scss';

const EditField: React.FunctionComponent<IEditFieldProps> = function ({
  margin,
  variant,
  onChange,
  onBlur,
  value,
  name,
  label,
  isEditable,
  suffix,
}) {

  const [status, setStatus] = useState('done');

  const handleBlurPaymentAmountField = function (e: any) {
    setStatus('done');
    onBlur && onBlur(e);
  }

  const [labelWidth, setLabelWidth] = useState(0);
  const labelElement: React.RefObject<HTMLLabelElement> = useRef(null);
  useEffect(() => {
    if (labelElement.current !== null) {
      setLabelWidth(labelElement.current.offsetWidth);
    };
  }, [status]);

  let inputElement: React.RefObject<HTMLInputElement> = useRef(null);
  const handleClickEditButton = function (e: any) {
    setStatus('editing');
  }
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    };
  }, [status]);

  const isEdititng = status === 'editing';

  return <>
    {
      isEdititng ?
        <FormControl
          margin={margin}
          variant={variant}
          fullWidth
          className={'edit_field'}
        >
          <InputLabel
            htmlFor={`${name}-anchor`}
            ref={labelElement}
            shrink
          >
            {label}
          </InputLabel>
          <OutlinedInput
            id={`${name}-anchor`}
            inputRef={inputElement}
            onBlur={handleBlurPaymentAmountField}
            value={value}
            name={name}
            onChange={onChange}
            labelWidth={labelWidth}
            type="number"
            startAdornment={
              <InputAdornment position="start">$</InputAdornment>
            }
          />
        </FormControl> :
        <div className='edit_field-display'>
          <InputLabel
            htmlFor={`${name}-anchor`}
            shrink
          >
            {label}
          </InputLabel>
          <div className='edit_field-display_content'>
            <Typography>
              {`$ ${value || 0} ${suffix ? suffix : ''}`}
            </Typography>
            {
              isEditable &&
              <Button
                className={'edit_field-button'}
                variant="contained"
                onClick={handleClickEditButton}
              >
                Edit
              </Button>
            }
          </div>
        </div>
    }
  </>;
}

export default EditField;
export type IEditFieldProps = Omit<
  (
    {
      label: string;
      isEditable: boolean;
      suffix?: string | null;
    } &
    FormControlProps &
    OutlinedInputProps
  ),
  'labelWidth'
>;