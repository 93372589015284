import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import http from '../config/axios';
import { ICreatePaymentDTO } from '../../server/src/types';

const PAYMENT_URL = 'payment';
//const PAYMENT_URL = 'http://localhost:3001/api/payment';

export function createPayment(cardData: ICreatePaymentDTO, meta: any): Promise<{
  data: any;
  code: number;
  message: string;
}> {
  return http.post(`${PAYMENT_URL}`, {
    ...cardData,
    meta,
  });
}

export function getPayment() {
  return http.get(`${PAYMENT_URL}`);
}

export function sendDataToRefferer(reffererUrl: string, data: any) {
  const form = document.createElement('form');
  form.action = reffererUrl;
  form.method = 'POST';

  Object.keys(data).forEach(key => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
}

export function createAmexPayment(cardData: ICreatePaymentDTO, meta: any): Promise<{
  data: any;
  code: number;
  message: string;
}> {
  return http.post(`${PAYMENT_URL}/amex`, {
    ...cardData,
    meta,
  });
}