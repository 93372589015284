import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from 'react';
import './App.scss';
import Payment from './containers/Payment/Payment';
import { Container } from '@material-ui/core';

const App: React.FC = () => {
  return (
    <Container maxWidth="xs">
      <Payment />
    </Container>
  );
}

export default App;
